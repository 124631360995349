import { NewsService, Scope } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData, defaultCatch } from '@common/defaults';
import { ItemsPerComponent } from '@common/types/ItemsPerComponent';
import { ItemsPerPage } from '@common/types/ItemsPerPage';
import { getIncrementalNextPage } from '@web/utils/getIncrementalNextPage';

export const fetchHomepageNews = async (
    contextData: ContextData,
    currentPage: number,
    isClientSide: boolean,
    itemsPerPage: ItemsPerPage | ItemsPerComponent = ItemsPerPage.HUGE,
) => {
    const { nextPage, nextPerPage } = getIncrementalNextPage(currentPage, itemsPerPage);

    return ApiService({ contextData, isClientSide, service: NewsService })
        .listNewsByDomainId({
            contextId: contextData.context.id,
            experimentGroup: contextData.experimentGroup,
            scope: Scope.PUBLIC,
            perPage: nextPerPage,
            page: nextPage,
            checkNextPage: true,
            showOnlyHomepageItems: true,
        })
        .catch(defaultCatch);
};
