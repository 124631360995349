import { NewsCategory, NewsCategoryService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData, defaultCatch } from '@common/defaults';

export const fetchNewsCategoryByFunctionality = async ({
    contextData,
    isClientSide,
    functionality,
    isMedia,
}: {
    contextData: ContextData;
    isClientSide: boolean;
    functionality: string;
    isMedia?: boolean;
}): Promise<NewsCategory | undefined> => {
    return ApiService({ contextData, isClientSide, service: NewsCategoryService })
        .getByFunctionalityNewsCategoryByDomainId({
            contextId: contextData.context.id,
            functionality: functionality,
            isMedia: isMedia,
        })
        .catch(defaultCatch);
};
