import { FC, memo, useState } from 'react';

import { useTranslation } from '@cms/i18n';
import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Header } from '@web/atoms/Header';
import { Widget, WidgetTitle } from '@web/molecules/Widget';
import styles from '@web/molecules/Widget/Widget.module.scss';
import { SidebarWidgetProps, WidgetType } from '@web/organisms/Sidebar/types';
import { Tabs } from '@web/organisms/SimpleWidget/atoms';
import { TabbedWidgetConfig } from '@web/organisms/SimpleWidget/types';

import { Widget as MostCommented, widgetConfig as mostCommentedConfig } from './MostCommented';
import { Widget as MostRead, widgetConfig as mostReadConfig } from './MostRead';
import { Widget as ProNews, widgetConfig as proNewsConfig } from './ProNews';

const DEFAULT_PLATFORM = PlatformID.VP;
const DEFAULT_WIDGET_CONFIG: TabbedWidgetConfig = {
    title: 'popular.title',
    tabs: [mostReadConfig, mostCommentedConfig],
};
const FULLNAME_PLATFORMS: PlatformID[] = [PlatformID.BR, PlatformID.GP];

type TabConfigByPlatform = Partial<Record<PlatformID, TabbedWidgetConfig>>;
const tabbedWidgetConfig: TabConfigByPlatform = {
    [PlatformID.BR]: {
        ...DEFAULT_WIDGET_CONFIG,
        tabs: [mostReadConfig],
    },
    [PlatformID.VI]: {
        title: 'vi.popular.title',
        tabs: [{ ...mostReadConfig, title: 'vi.popular.tab-1-title' }, proNewsConfig],
    },
};

interface Props {
    dataList?: SidebarWidgetProps;
}

const TabbedWidget: FC<Props> = ({ dataList }) => {
    const __sidebar = useTranslation('sidebar').t;

    const { context, platform } = useContextData();
    const platformID = context?.platform || DEFAULT_PLATFORM;
    const useFullname = FULLNAME_PLATFORMS.includes(platformID);
    const platformName = useFullname ? platform.name : platformID.toUpperCase();

    const config = tabbedWidgetConfig[platformID] || DEFAULT_WIDGET_CONFIG;
    const [highlighted, setHighlighted] = useState(config.tabs[0]);
    const heading = __sidebar(config.title, { platform: platformName });

    const onTabClick = (clickedTab: string) => {
        if (highlighted.metaKey !== clickedTab) {
            const findTab = config.tabs.find((tab) => tab.metaKey === clickedTab);
            if (findTab) {
                setHighlighted(findTab);
            }
        }
    };

    return (
        <Widget>
            <Header>
                <WidgetTitle
                    heading={heading}
                    classes={config.tabs.length > 1 ? styles['border-override'] : styles['border-third']}
                />
                {config.tabs && config.tabs.length > 1 ? (
                    <>
                        <hr />
                        <Tabs tabs={config.tabs} onTabClick={onTabClick} highlighted={highlighted} />
                    </>
                ) : null}
            </Header>
            <hr />
            {highlighted?.metaKey === WidgetType.MOST_READ ? (
                <MostRead dataList={dataList ? dataList[WidgetType.MOST_READ] : []} />
            ) : null}
            {highlighted?.metaKey === WidgetType.MOST_COMMENTED ? (
                <MostCommented dataList={dataList ? dataList[WidgetType.MOST_COMMENTED] : []} />
            ) : null}
            {highlighted?.metaKey === WidgetType.PRO_NEWS ? (
                <ProNews dataList={dataList ? dataList[WidgetType.PRO_NEWS] : []} />
            ) : null}
        </Widget>
    );
};
export const NewsPopular = memo(TabbedWidget);
