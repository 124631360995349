import { GrandPrix, GrandPrixService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData, defaultCatch } from '@common/defaults';

const MAX_DAYS_UNTIL_NEXT_GP = 14;

export const fetchUpcomingGrandPrix = async (
    contextData: ContextData,
    isClientSide: boolean = true,
): Promise<GrandPrix | undefined> => {
    return ApiService({ contextData, isClientSide, service: GrandPrixService })
        .upcomingGrandPrixByDomainId({
            contextId: contextData.context.id,
            days: MAX_DAYS_UNTIL_NEXT_GP,
        })
        .catch(defaultCatch);
};
