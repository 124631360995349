import { News, NewsService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData, defaultArrayCatch } from '@common/defaults';

type FetchParams = {
    isMedia?: boolean;
    functionality?: string;
};

export const fetchMostReadNews =
    (fetchParams: FetchParams) =>
    async (contextData: ContextData, isClientSide: boolean = true): Promise<News[]> => {
        return ApiService({ contextData, isClientSide, service: NewsService })
            .getMostReadNewsByDomainId({
                contextId: contextData.context.id,
                experimentGroup: contextData.experimentGroup,
                isMedia: fetchParams.isMedia,
                functionality: fetchParams.functionality,
            })
            .catch(defaultArrayCatch);
    };
