import { Comment, CommentService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData, defaultArrayCatch } from '@common/defaults';

export const fetchMostLikedComments = async (
    contextData: ContextData,
    isClientSide: boolean = true,
): Promise<Comment[]> => {
    return ApiService({ contextData, isClientSide, service: CommentService })
        .getMostLikesCommentByDomainId({
            contextId: contextData.context.id,
        })
        .catch(defaultArrayCatch);
};
