import { News, NewsService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData, defaultArrayCatch } from '@common/defaults';

export const fetchMostCommentedNews = async (contextData: ContextData): Promise<News[]> => {
    return ApiService({ contextData, isClientSide: false, service: NewsService })
        .getMostCommentedNewsByDomainId({
            contextId: contextData.context.id,
            experimentGroup: contextData.experimentGroup,
        })
        .catch(defaultArrayCatch);
};
